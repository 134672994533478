import Emittery from 'emittery';
import { BifrostSubscription } from './models';
import _ from 'lodash';

export function createBifrostSubscription<T>(a: { dispose: () => void }): BifrostSubscription<T> {
  const ee = new Emittery();

  let lastTimestamp = 0;
  let currentValue: any;

  return new BifrostSubscription({
    nextData: (
      data: T,
      opts: { skipEqualityCheck?: boolean; ensureSequentialTimestamp?: number; shouldAddValueToCache?: boolean } = {}
    ) => {
      const hasChanged = opts?.skipEqualityCheck === true ? true : !_.isEqual(currentValue, data);
      const sequenceIsGood = opts.ensureSequentialTimestamp ? opts.ensureSequentialTimestamp > lastTimestamp : true;
      const shouldEmit = sequenceIsGood && hasChanged;
      if (shouldEmit) {
        currentValue = data;
        lastTimestamp = opts.ensureSequentialTimestamp;
        const shouldAddValueToCache: boolean =
          typeof opts.shouldAddValueToCache === 'undefined' ? true : opts.shouldAddValueToCache;

        ee.emit('data', {
          data,
          meta: {
            shouldAddValueToCache
          }
        });
      }
    },
    dispose: () => {
      try {
        ee.clearListeners();
        a.dispose();
      } catch (e) {
        console.error('Unable to dispose', e);
      }
    },
    onData: (fn) => {
      // If data as previously been emitted provide it to the function
      if (currentValue) {
        fn(currentValue, { shouldAddValueToCache: true });
      }
      ee.on('data', ({ data, meta }) => {
        fn(data, meta);
      });
    },
    onError: (errorFn: (e: Error) => void) => {
      ee.on('error', errorFn);
    },
    nextError: (e: Error) => {
      ee.emit('error', e);
    }
  });
}
