import fastSafeStringify from 'fast-safe-stringify';

//Need to be real robust to failure...
export function objectHash(arg: any) {
  try {
    //Wrap in try-catch b/c sometimes the argument is an immutable object and stable stringify blows up...
    return djb2(fastSafeStringify.stableStringify(arg, customReplacer));
  } catch (e) {
    try {
      return JSON.stringify(arg, (key, value) => {
        if (isObject(value) && value.constructor.name !== 'Object') {
          return Object.keys(value).sort();
        } else {
          return value;
        }
      });
    } catch (er) {
      return djb2(String(arg));
    }
  }
}

//Stupid simple but fast djb2 string hash https://github.com/yocontra/djb2/blob/master/index.js
function djb2(str: string) {
  let prev = 5381;
  for (let i = 0; i < str.length; i++) {
    prev = (prev << 5) + prev + str[i]!.charCodeAt(0);
  }

  return Math.abs(prev).toString();
}

function customReplacer(key, value) {
  if (value instanceof Array) {
    return value.slice().sort(customComparator);
  } else {
    return value;
  }
}

function isObject(o) {
  return Object.prototype.toString.call(o) === '[object Object]';
}

function customComparator(val1: unknown, val2: unknown) {
  const comparator1 = typeof val1 === 'object' && val1 !== null && 'id' in val1 ? (val1 as any).id : val1;
  const comparator2 = typeof val2 === 'object' && val2 !== null && 'id' in val2 ? (val2 as any).id : val2;

  if (comparator1 === comparator2) {
    return 0;
  } else {
    return comparator1 > comparator2 ? 1 : -1;
  }
}
