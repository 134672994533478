//Generates a promise that ALSO has a `resolve` and `reject` property, to let the promise be resolved elsewhere
export function deferred<T = any>() {
  let resolve: (val: any) => void, reject: (val: Error) => void;

  const prom: any = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  prom.resolve = (val: any) => {
    prom.isResolved = true;
    resolve(val);
  };

  prom.reject = (val: any) => {
    prom.isRejected = true;
    reject(val);
  };

  Object.assign(prom, {
    isResolved: false,
    isRejected: false
  });

  return prom as Deferred<T>;
}

export type Deferred<T> = Promise<T> & ExtraDeferredProps<T>;

type ExtraDeferredProps<T> = {
  resolve: (val?: T) => void;
  reject: (val?: any) => void;
  isResolved: boolean;
  isRejected: boolean;
};

//Ripped from https://github.com/lukeed/dequal/blob/master/src/lite.js
//Modified to ignore array order if id property exists
var has = Object.prototype.hasOwnProperty;
export function dequal(foo, bar, blah?: string) {
  var ctor, len;
  if (foo === bar) return true;

  if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
    if (ctor === Array) {
      if ((len = foo.length) === bar.length) {
        if (typeof foo[0]?.id === 'string') {
          const fooKeyedById: Record<string, any> = {};
          foo.forEach((val, i) => {
            fooKeyedById[val.id || i] = val;
          });

          return bar.every((val, i) => dequal(val, fooKeyedById[val.id || i]));
        } else {
          while (len-- && dequal(foo[len], bar[len]));
        }
      }
      return len === -1;
    }

    if (!ctor || typeof foo === 'object') {
      len = 0;
      for (ctor in foo) {
        if (has.call(foo, ctor) && ++len && !has.call(bar, ctor)) return false;
        if (!(ctor in bar) || !dequal(foo[ctor], bar[ctor])) return false;
      }
      return Object.keys(bar).length === len;
    }
  }

  return foo !== foo && bar !== bar;
}
