import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import type { QueryCache, QueryClient, useQueries as useQueriesType } from 'react-query';
import _ from 'lodash';
import { deferred, dequal } from './utils';

//Hardcoded regex of routes that we know are are writes, not reads... A bit fragile but good enough for now
//Includes conversation and message collections, since those are handled in a separate cache in chatUIStore.ts
const OLLIE_WRITES_REGEX =
  /(__set|update|__clear|__persist|trigger|notify|register|__save|__take|__add|__end|enqueue|__edit|__delete|upload|create)/i;

const OLLIE_READS_REGEX = /(conversation__|message__)/i;

import {
  BifrostInstance,
  FetchReturnType,
  BifrostSubscription,
  HttpProcessor,
  Logger,
  BifrostInstanceFn,
  HelperOptions,
  SubscriptionHelperOptions
} from './models';
import { objectHash } from './object-hash';
import { UseQueryResult } from 'react-query';

export {
  UnpackBifrostSubscription,
  BifrostInstance,
  BifrostSubscription,
  UnpackPromise,
  ExpandedUseFetchReturnType
} from './models';
export { registerFunctionsWithExpress } from './expressHelpers';
export { createBifrostSubscription } from './subscriptions-helpers';

export * from './misc';

type InvocationType =
  | 'useClient'
  | 'useServer'
  | 'useClientSubscription'
  | 'getClientSubscription'
  | 'fetchServer'
  | 'fetchClient';

type CreateBifrostOpts<FunctionsType extends Record<string, Function>> = {
  fns: FunctionsType;
  reactModule: any;
  useOnFocusChange?: (fn: (isFocused: boolean) => void) => void;
  interceptor?: <T>(a: {
    next: () => Promise<T>;
    fnName: string;
    payload: any;
    invocationType: InvocationType;
  }) => Promise<T>;
  httpProcessor?: HttpProcessor;
  logger?: Logger;
};

export function createBifrost<T extends Record<string, Function>>(p: CreateBifrostOpts<T>): BifrostInstance<T> {
  const localFnSDK = {} as BifrostInstance<T>;
  Object.keys(p.fns).forEach((fnName) => {
    (localFnSDK as any)[fnName] = new InstanceFns({ ...p, fnName });
  });

  return localFnSDK;
}

const DEFAULT_STALE_TIME = process.env.NODE_ENV === 'development' ? 1000 * 10 : 1000 * 30;

let queryClient: QueryClient = null;
export function getQueryClient(): any {
  if (!queryClient) {
    const { QueryClient } = require('react-query');

    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          keepPreviousData: true,
          staleTime: DEFAULT_STALE_TIME,
          cacheTime: 1000 * 60 * 60 * 24 * 10, //10 days
          structuralSharing: true,
          notifyOnChangeProps: ['data', 'error', 'status']
        }
      }
    });
  }

  return queryClient;
}

class InstanceFns<T extends Record<string, Function>> {
  private context: CreateBifrostOpts<T> & { fnName: string };

  constructor(context: CreateBifrostOpts<T> & { fnName: string }) {
    this.context = context;
  }

  fetchClient: (arg: any, invocationType?: InvocationType, cacheKey?: string) => Promise<FetchReturnType<any>> = async (
    p,
    invocationType = 'fetchClient' as const,
    cacheKey
  ) => {
    const fn = this.context.fns[this.context.fnName];
    if (!fn) {
      throw new Error('Unable to fetch client since function cannot be found');
    }

    const prom = this.withInterceptor({ fn: () => fn(p), payload: p, invocationType });

    const data = await prom;

    this.setCachedValuePrivate(cacheKey || this.computeCacheKey(p), data, invocationType);

    return {
      data,
      isFromCache: false
    };
  };
  fetchServer: (arg: any, invocationType?: InvocationType, cacheKey?: string) => Promise<FetchReturnType<any>> = async (
    p,
    invocationType = 'fetchServer' as const,
    cacheKey
  ) => {
    if (!this.context.httpProcessor) {
      throw new Error('May not fetch server if no http processor is defined');
    }

    const data = await this.withInterceptor({
      fn: () => this.context.httpProcessor({ fnName: this.context.fnName, payload: p }),
      payload: p,
      invocationType
    });
    this.setCachedValuePrivate(cacheKey || this.computeCacheKey(p), data, invocationType);

    return {
      data,
      isFromCache: false
    };
  };

  private withInterceptor<Val>(p: { fn: () => Promise<Val>; payload: any; invocationType: InvocationType }) {
    if (!this.context.interceptor) {
      return p.fn();
    }

    return this.context.interceptor({
      next: p.fn,
      fnName: this.context.fnName,
      payload: p.payload,
      invocationType: p.invocationType
    });
  }

  private setCachedValuePrivate(key: string, newVal: any, type: InvocationType) {
    if (!shouldAddToCache(key, type) || areDeeplyEqual(newVal, this.getCachedValueByKey(key))) {
      return;
    }

    return getQueryClient().setQueryData(key, newVal);
  }

  public getCachedValueByKey = (key: string) => {
    return getQueryClient().getQueryData(key);
  };

  public getAllCachedValues() {
    return Object.keys(this.allPreviouslyAccessedCacheKeys).map((k) => {
      return getQueryClient().getQueryState(k);
    });
  }

  public getCachedValue = (p: any) => {
    return getQueryClient().getQueryData(this.computeCacheKey(p));
  };

  public setCachedValue = (p: any, newVal: any) => {
    return getQueryClient().setQueryData(this.computeCacheKey(p), newVal);
  };

  //NOTE: Dedupe subscriptions with this complicated wrapper so that we don't set up multiple subscriptions when a multiple components subscribe to the data
  private subscriptionInfoByCacheKey: Record<
    string,
    {
      isCachedValueLive: boolean;
      refresh?: () => void;
      dispose?: () => void;
      listeners: Map<any, (a: any) => void>;
    }
  > = {};
  private onDedupedSubscription(p: any, subscribeFn: any, invocationType: InvocationType, cacheKey: string) {
    let hasUnsubscribed = false;

    const subscribeAndSetDisposeAndRefresh = () => {
      this.withInterceptor({
        invocationType,
        fn: () =>
          new Promise<void>((res) => {
            if (!this.subscriptionInfoByCacheKey[cacheKey] || hasUnsubscribed) {
              res();
              return;
            }

            const subFn = this.context.fns[this.context.fnName];

            const sub = subFn(p);

            if (!sub || !('onData' in sub) || 'then' in sub) {
              throw new Error(
                `The function for ${this.context.fnName} does not return a valid bifrost subscription! Does the function return a promise? (e.g. is an async function)`
              );
            }
            sub.onData((val, meta) => {
              if (!this.subscriptionInfoByCacheKey[cacheKey]) {
                return;
              }

              this.subscriptionInfoByCacheKey[cacheKey].isCachedValueLive = true;
              this.subscriptionInfoByCacheKey[cacheKey].listeners.forEach((fn) => {
                fn({ data: val, isFromCache: false });
              });

              this.setCachedValuePrivate(cacheKey, val, invocationType);

              res();
            });

            sub.onError((err) => {
              console.error(err);
              if (!this.getCachedValueByKey(cacheKey)) {
                //TODO: Somehow propagate the error to react-query... https://github.com/tannerlinsley/react-query/discussions/1295
              }
            });

            this.subscriptionInfoByCacheKey[cacheKey].dispose = () => sub.dispose();
            this.subscriptionInfoByCacheKey[cacheKey].refresh = () => {
              this.subscriptionInfoByCacheKey[cacheKey].isCachedValueLive = false;
              this.subscriptionInfoByCacheKey[cacheKey].dispose?.();
              delete this.subscriptionInfoByCacheKey[cacheKey].dispose;
              delete this.subscriptionInfoByCacheKey[cacheKey].refresh;
              subscribeAndSetDisposeAndRefresh();
            };

            return sub;
          }),
        payload: p
      }).catch((e) => {
        console.error(e);
      });
    };

    //If for some reason there's no cached value but there is a subscription, then something has messed up and we need to refresh. E.g. the cache was cleared externally.
    if (this.subscriptionInfoByCacheKey[cacheKey] && this.getCachedValueByKey(cacheKey) === undefined) {
      this.subscriptionInfoByCacheKey[cacheKey].refresh?.();
    }

    if (!this.subscriptionInfoByCacheKey[cacheKey]) {
      this.subscriptionInfoByCacheKey[cacheKey] = {
        isCachedValueLive: false,
        listeners: new Map()
      };

      subscribeAndSetDisposeAndRefresh();
    }

    this.subscriptionInfoByCacheKey[cacheKey].listeners.set(subscribeFn, subscribeFn);

    return {
      unsubscribe: () => {
        hasUnsubscribed = true;

        if (!this.subscriptionInfoByCacheKey[cacheKey]) {
          return;
        }

        this.subscriptionInfoByCacheKey[cacheKey]?.listeners.delete(subscribeFn);

        if (this.subscriptionInfoByCacheKey[cacheKey]?.listeners.size === 0) {
          this.subscriptionInfoByCacheKey[cacheKey]?.dispose?.();
          delete this.subscriptionInfoByCacheKey[cacheKey];
        }
      }
    };
  }

  getClientSubscription(
    p: unknown,
    callOpts?: { disableCache?: boolean; queryKey?: string },
    invocationType: InvocationType = 'getClientSubscription' as const
  ) {
    return {
      subscribe: (fn) => {
        const cacheKey = callOpts?.queryKey || this.computeCacheKey(p);
        const cachedValue = this.getCachedValueByKey(cacheKey);

        if (typeof cachedValue !== 'undefined') {
          const cacheEnabled = !callOpts?.disableCache;
          if (cacheEnabled || this.subscriptionInfoByCacheKey[cacheKey]?.isCachedValueLive) {
            fn({ data: cachedValue, isFromCache: !this.subscriptionInfoByCacheKey[cacheKey]?.isCachedValueLive });
          }
        }

        return this.onDedupedSubscription(p, fn, invocationType, cacheKey);
      }
    };
  }

  useClientSubscription(p: any, callOptsRaw: SubscriptionHelperOptions = {}) {
    if (this.context.fnName.match(OLLIE_READS_REGEX) && !this.context.fnName.includes('Registration')) {
      throw new Error(
        `Do not use bifrost useClientSubscription for message and conversation subscriptions due to data propagation issue! Instead import \`api\` and call \`api.${this.context.fnName}(...)\``
      );
    }

    const cacheKey = callOptsRaw?.queryKey?.toString() || this.computeCacheKey(p);

    const { queryKey, queryFn, enabled: enabledRaw, ...callOpts } = callOptsRaw;
    if (callOpts.notifyOnMetaDataChanges) {
      callOpts.notifyOnChangeProps = 'tracked';
    }
    const enabled = (callOpts.skip ? false : null) ?? enabledRaw ?? true;

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const firstResolvePromsByCacheKey = useRef({});

    const getFirstResolvePromByCacheKey = (k: string) => {
      if (!firstResolvePromsByCacheKey.current[k]) {
        firstResolvePromsByCacheKey.current[k] = deferred();
      }
      return firstResolvePromsByCacheKey.current[k];
    };

    this.useExtraDependencies({
      extraDependencies: callOpts.extraDependencies,
      refresh: () => {
        this.subscriptionInfoByCacheKey[cacheKey]?.refresh?.();
        return Promise.resolve();
      },
      enabled
    });

    //eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (enabled) {
        const sub = this.getClientSubscription(
          p,
          {
            disableCache: true,
            queryKey: cacheKey
          },
          'useClientSubscription'
        );
        const unsub = sub.subscribe((data) => {
          if (callOpts.notifyOnMetaDataChanges && !getFirstResolvePromByCacheKey(cacheKey).isResolved) {
            getFirstResolvePromByCacheKey(cacheKey).resolve(data.data);
          }
        });
        return () => {
          unsub.unsubscribe();
        };
      }
    }, [cacheKey, enabled]);

    const { useQuery } = require('react-query');

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const ret = useQuery({
      queryKey: cacheKey,
      queryFn: () => {
        return getFirstResolvePromByCacheKey(cacheKey).then(() => {
          return this.getCachedValueByKey(cacheKey);
        });
      },
      enabled,
      ...callOpts
    });

    return {
      ...ret,
      isFromCache: !ret.isFetchedAfterMount
    };
  }

  useClient(p: unknown, callOpts: HelperOptions = {}) {
    const { useQuery } = require('react-query');
    if (callOpts.notifyOnMetaDataChanges) {
      callOpts.notifyOnChangeProps = 'tracked';
    }

    const enabled = typeof callOpts?.skip === 'boolean' ? !callOpts?.skip : callOpts?.enabled ?? true;

    const cacheKey = callOpts?.queryKey?.toString() || this.computeCacheKey(p);

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const ret = useQuery({
      queryKey: cacheKey,
      queryFn: () => {
        return new Promise((res, rej) => {
          this.fetchClient(p, 'useClient')
            .then((a) => {
              setTimeout(
                () => res(a.data),
                !callOpts.notifyOnMetaDataChanges && areDeeplyEqual(a.data, this.getCachedValueByKey(cacheKey))
                  ? 10000
                  : 0
              );
            })
            .catch((e) => rej(e));
        });
      },
      staleTime: callOpts?.useCacheOnlyWithinMS,
      enabled,
      ...callOpts
    });

    this.context.useOnFocusChange?.((isFocused) => {
      if (isFocused && !callOpts.disableRefetchOnFocus) {
        ret.refetch();
      }
    });

    this.useExtraDependencies({
      extraDependencies: callOpts.extraDependencies,
      refresh: ret.refetch,
      enabled
    });

    this.useForceRefetchAtTimestamp({
      forceRefetchAtTimestamp: callOpts?.forceRefetchAtTimestamp,
      refetch: ret.refetch,
      enabled
    });

    return {
      ...ret,
      isFromCache: !ret.isFetchedAfterMount,
      forceRefresh: ret.refetch
    };
  }

  useServer(p: unknown, callOpts: HelperOptions = {}) {
    const { useQuery } = require('react-query');
    if (callOpts.notifyOnMetaDataChanges) {
      callOpts.notifyOnChangeProps = 'tracked';
    }

    const cacheKey = callOpts?.queryKey?.toString() || this.computeCacheKey(p);

    const enabled = typeof callOpts?.skip === 'boolean' ? !callOpts?.skip : callOpts?.enabled ?? true;
    //eslint-disable-next-line react-hooks/rules-of-hooks
    const ret = useQuery({
      queryKey: cacheKey,
      queryFn: () => this.fetchServer(p, 'useServer').then((a) => a.data),
      staleTime: callOpts?.useCacheOnlyWithinMS,
      enabled,
      ...callOpts
    });

    this.context.useOnFocusChange?.((isFocused) => {
      if (isFocused && !callOpts.disableRefetchOnFocus) {
        ret.refetch();
      }
    });

    this.useExtraDependencies({
      extraDependencies: callOpts.extraDependencies,
      refresh: ret.refetch,
      enabled
    });

    this.useForceRefetchAtTimestamp({
      forceRefetchAtTimestamp: callOpts?.forceRefetchAtTimestamp,
      refetch: ret.refetch,
      enabled
    });

    return {
      ...ret,
      isFromCache: !ret.isFetchedAfterMount,
      forceRefresh: ret.refetch
    };
  }

  private useExtraDependencies(p: {
    extraDependencies: SubscriptionHelperOptions['extraDependencies'];
    refresh: () => Promise<any>;
    enabled: boolean;
  }) {
    const extraDependencies = (p.extraDependencies || []).join('');

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const enabledRef = useShadowRef(p.enabled);

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const hasFiredOnce = useRef(false);
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!hasFiredOnce.current) {
        hasFiredOnce.current = true;
      } else if (enabledRef.current) {
        p.refresh().catch((e) => {
          console.error('Unable to refetch via useExtraDependencies!');
          console.error(e);
        });
      }
    }, [extraDependencies]);
  }

  private useForceRefetchAtTimestamp(p: {
    forceRefetchAtTimestamp?: number;
    refetch: () => Promise<any>;
    enabled: boolean;
  }) {
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      let isMounted = true;
      const refetchTimestamp = p.forceRefetchAtTimestamp ?? 0;
      const msUntilRefetch = refetchTimestamp > Date.now() ? refetchTimestamp - Date.now() : null;

      if (msUntilRefetch && p.enabled) {
        setTimeout(() => {
          if (isMounted && p.enabled) {
            p.refetch().catch((e) => {
              console.error('Unable to forceRefetchAtTimestamp', e);
            });
          }
        }, msUntilRefetch);
      }

      return () => {
        isMounted = false;
      };
    }, [p?.forceRefetchAtTimestamp, p.enabled]);
  }

  private allPreviouslyAccessedCacheKeys: Record<string, true> = {};
  private computeCacheKey(arg: any) {
    const cacheKey = getCacheKey(this.context.fnName, arg);
    this.allPreviouslyAccessedCacheKeys[cacheKey] = true;
    return cacheKey;
  }
}

export function getCacheKey(fnName: string, arg: any) {
  return `${fnName}-${objectHash(arg)}`;
}

//Shadows a value inside a ref (typically so that it's accessible inside a callback or such)
export function useShadowRef<T>(val: T) {
  const ref = useRef(val);
  useEffect(() => {
    ref.current = val;
  });

  return ref;
}

function shouldAddToCache(key: string, invocationType: InvocationType) {
  if (OLLIE_READS_REGEX.test(key) && !key.includes('Registration')) {
    return false;
  }

  if (invocationType === 'fetchClient' || invocationType === 'fetchServer') {
    return !OLLIE_WRITES_REGEX.test(key);
  }

  return true;
}

function areDeeplyEqual(a, b) {
  return dequal(a, b);
}
